<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("PACKAGES.PACKAGES_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openPackageCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_PACKAGES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("PACKAGES.ADD_PACKAGE") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_PACKAGES)"
              :objectType="'packages'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <package-list-table
        @onViewPackage="openPackageViewModal"
        @onEditPackage="openPackageEditModal"
        @onDeletePackage="deletePackage"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PACKAGE'"
          @onCloseModal="closePackageModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PACKAGES.VIEW_PACKAGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openPackage"
                :objectType="'packages'"
                :objectId="openPackage.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openPackageEditModal(openPackage)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="edit" @click="deletePackage(openPackage)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePackageModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-package-page v-if="openPackage" :packageId="openPackage.id" />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PACKAGE'"
          @onCloseModal="closePackageModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PACKAGES.EDIT_PACKAGE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="openPackageViewModal(openPackage)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-package-page
              v-if="openPackage"
              :packageId="openPackage.id"
              @onViewPackage="openPackageViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPackageModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPackageModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PACKAGE'"
          @onCloseModal="closePackageModal"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("PACKAGES.ADD_PACKAGE") }}
            </h1>
            <button class="close" @click="closePackageModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-package-page @onViewPackage="openPackageViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PackageListTable from "./partials/PackageListTable.vue";
import EditPackagePage from "./components/EditPackageComponent.vue";
import AddPackagePage from "./components/AddPackageComponent.vue";
import ViewPackagePage from "./components/ViewPackageComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PackageListTable,
    NotificationSubscription,
    EditPackagePage,
    AddPackagePage,
    ViewPackagePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPackageModalOpened = false;
    let isEditPackageModalOpened = false;
    let isAddPackageModalOpened = false;
    let openPackage = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPackageModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPackageModalOpened = true;
      }
      openPackage = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPackageModalOpened = true;
    }
    return {
      isViewPackageModalOpened: isViewPackageModalOpened,
      isEditPackageModalOpened: isEditPackageModalOpened,
      isAddPackageModalOpened: isAddPackageModalOpened,
      openPackage: openPackage,
      renderKey: 1,
    };
  },

  methods: {
    openPackageCreateModal() {
      this.closePackageModal();
      this.isAddPackageModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Packages",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openPackageViewModal(pack, reRender = false) {
      this.closePackageModal();
      this.openPackage = pack;
      this.isViewPackageModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Packages",
          query: { id: this.openPackage.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openPackageEditModal(pack) {
      this.closePackageModal();
      this.openPackage = pack;
      this.isEditPackageModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Packages",
          query: { id: this.openPackage.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closePackageModal() {
      this.isAddPackageModalOpened = false;
      this.isViewPackageModalOpened = false;
      this.isEditPackageModalOpened = false;
      this.openPackage = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Packages",
          query: {},
        }).href
      );
    },

    async deletePackage(pack) {
      const confirmation = await swal.fire({
        title: this.$t("PACKAGES.DELETE_THIS_PACKAGE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("packages/destroy", pack.id);
          this.renderKey++;
          this.closePackageModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("PACKAGES.PACKAGE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
